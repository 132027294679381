/**
 * Enum-like object representing the available theme options for the application.
 * The `Object.freeze` method is used to make the object immutable.
 *
 * @author Helmi Ben Eroi
 * @date 2024-08-16
 *
 * @type {Object}
 * @property {string} template1 - Represents the template1 theme.
 * @property {string} template2 - Represents the template2 theme. 
 * @property {string} templateN - Represents the templateN theme.
 */
export const themeEnums = Object.freeze({ 
  template1: 'TEMPLATE_1',
  template2: 'TEMPLATE_2',
  });
  
export const keyTemplateSettings = Object.freeze({ 
  bannerHomePagefooter1:'BANNER_HOME_PAGE_FOOTER_1',
  bannerHomePageHeader1:'BANNER_HOME_PAGE_HEADER_1',
  logo:'LOGO', 
  address:'ADDRESS',
  links:'LINKS',
  socialNetworks:'SOCIAL_NETWORKS'
});
  


/**
 * articleType
 */
  
export const articleType = Object.freeze({ 
  model:'MODEL', 
simple:'SIMPLE',
declination:'DECLINATION',
regroupement:'REGROUPEMENT'
});
   