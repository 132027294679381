import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'template/App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';   
import { CustomThemeProvider } from "./context/ThemeContext";
import { SettingsAppsProvider } from "./context/SettingsAppsContext";
import { GlobalStateProvider } from 'context/GlobalStateContext';
import ErrorHandlingAndLoading from 'context/ErrorHandlingAndLoading';
import {AuthProvider} from "context/AuthContext"
const root = ReactDOM.createRoot(document.getElementById('root'));
/**
 * Entry point for the React application.
 * Renders the main `App` component wrapped in context providers for theme and settings.
 * Uses `React.StrictMode` to help identify potential problems in the application.
 *
 * @author Helmi Ben Eroi
 * @date 2024-08-16
 */
root.render(
  <React.StrictMode>
    <SettingsAppsProvider>
       <CustomThemeProvider> 
       <ErrorHandlingAndLoading>
        <GlobalStateProvider>
         <AuthProvider>
          <CssBaseline />
          <App />
          </AuthProvider> 
        </GlobalStateProvider>
        </ErrorHandlingAndLoading>
       </CustomThemeProvider>
   </SettingsAppsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
