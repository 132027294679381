
import {test_api,api} from "../index";
export const useGetProducts=(params={ 
    freeField:'',
    universeId:null,
    pageNumber:null,
    pageSize:null 
})=>{
    const {
        freeField,
        universeId,
        pageNumber,
        pageSize 
    } = params;
    return api.get(`/dossiers/articles/ecom/simpleArticles?${freeField ? "&freeField="+freeField :''}${
        !!universeId ? "&universeId="+universeId : ""}&pageNumber=${pageNumber || 1}&pageSize=${pageSize || 25}&sort=creationDate`)
};


export const useGetProductsById =(id)=>{
    return api.get(`/dossiers/articles/ecom/${id || ""}`)

}
 
export const useGetProductsByCategories =(category)=>{
    return test_api.get(`/products/category/${category || ""}`)

}

 export const useGetArticleMainPhotoByArticleId = id =>
        api.get(`/dossiers/articles/${id}/mainPhotos`);
    
