import api from "../index";
 
/**
 * Retrieves a token or triggers sign-in based on the provided licence number and action.
 * 
 * @param {string} licenceNumber - The licence number to be used for token retrieval or sign-in.
 * @param {string} [customerEmail] - The customer's email, required if the action is to generate an e-commerce token.
 * @param {string} [customerPassword] - The customer's password, required if the action is to generate an e-commerce token.
 * @param {string} actionEnum - The action to be performed. It could be "GENERATE_ECOMMERCE_CUSTOMER_TOKEN" or "GENERATE_UNAUTHENTICATED_TOKEN".
 * 
 * @returns {Promise} - The API response promise.
 */
export const getTokenOrSignInByLicenceNumber=({
    licenceNumber ='' ,
    customerEmail , 
    customerPassword ,
    actionEnum  ='GENERATE_UNAUTHENTICATED_TOKEN' })=> api.get(`/ecommerce/licence/token?licenceNumber=${licenceNumber || ''}&action=${actionEnum || ''}${  
        `&customerEmail=${customerEmail ?? null}&customerPassword=${customerPassword ?? null}` 
        }`) ;
 
 export const useGetTemplateData=()=> api.get(`/dossiers/Ecom/data`/*,{
            headers: {
                Authorization: `Bearer ${token}`,
                "X-LICENCE-CODE": licenceNumber
            } 
        }*/) ;
 
