import { createContext, useContext, useEffect, useState } from 'react';
import { useGlobalState } from "context/GlobalStateContext";
 import {isEmpty} from "loadsh";
import { setLicenceAuth, useSettingsApps, useSettingsAppsDispatch } from './SettingsAppsContext';
const AuthContext = createContext();

/**
 * Provider component for managing authentication state.
 * Provides context for `isAuthenticated` state and authentication actions (`login` and `logout`).
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The `AuthProvider` component.
 */
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEcom, setUserEcom] = useState({
    customerId : '' ,
    customerName : null
  });
  //const [ecommerceCustomerId,setEcommerceCustomerId] =useState(null);
  const {licenceData:{user:{licences} }} =useGlobalState();
  const { codeShop }  = useSettingsApps(); 
  const settingsAppsDispatch = useSettingsAppsDispatch();
  /**
   * Logs in the user by setting `isAuthenticated` to `true`.
   */
  const login = () => setIsAuthenticated(true);

  /**
   * Logs out the user by setting `isAuthenticated` to `false`.
   */
  const logout = () => {
    
    setIsAuthenticated(false)
  setTimeout(()=>{
    setLicenceAuth(settingsAppsDispatch, { codeShop: localStorage.getItem("codeShop"), licenceData: { token: null ,
      documentCode:null,
      saleDocument:{lines:[]},} });
    localStorage.setItem("maxRefreshToken", 0);

  },300)
  };

useEffect(()=>{ 
  if(!!codeShop && ! isEmpty(licences)){
    const customer = (licences || []).find(({licenceCode})=>(licenceCode === codeShop)); 
    setUserEcom({
      customerId : customer?.ecommerceCustomerId ,
      customerName : customer?.ecommerceCustomerName 
    });
    setIsAuthenticated(!!customer?.ecommerceCustomerId); 
  //  setEcommerceCustomerId(customerId || null)
}
},[licences,codeShop]);

  return (
    <AuthContext.Provider value={({ isAuthenticated,userEcom, login, logout })}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to access the authentication context.
 * Throws an error if used outside of `AuthProvider`.
 *
 * @returns {Object} The authentication context value, including `isAuthenticated`, `login`, and `logout`.
 */
 
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

export {useAuth}
