import saleAPI from "services/sale";
import { useContext, useEffect, useState } from "react";
import { usePromise } from "services/index";
import { ErrorAndLoadingContext } from "context/ErrorHandlingAndLoading";
import { getTranslation, TransformToNumber, useHandlerParamsApi } from "utils";
import { setBasket, useGlobalStateDispatch, useGlobalState } from "context/GlobalStateContext";
import { useSettingsApps } from "context/SettingsAppsContext";
/**
* Custom hook to create a sale document.
*
* This hook manages the state of the created sale document and provides
* a function to initiate the creation request.
*
* @returns {Object} - An object containing the following properties: 
* @returns {Function} createSaleDocument - Function to initiate the request to create a sale document.
*/
export const useCreateSaleDocument = () => {
    const globalStateDispatch = useGlobalStateDispatch();
    const { result, request } = usePromise(saleAPI.useCreateSaleDocument, {

        onSuccess: ({ data = false }) => {
            setBasket(globalStateDispatch, data);
        },
        // onFailure:()=>{ }
    })
    return {
        resultCreateSaleDocument: result,
        createSaleDocument: request
    }
};

export const useGetEcommerceBasketFromCustomerIdOrDocumentCode = () => {
    const globalStateDispatch = useGlobalStateDispatch();
    const { request } = usePromise(saleAPI.useGetEcommerceBasketFromCustomerIdOrDocumentCode, {
        onSuccess: ({ data = null }) => {
            setBasket(globalStateDispatch, data);
        },
        // onFailure:()=>{ }
    })
    return {
        getEcommerceBasket: request
    }
};

/**
 * A custom hook to update customer information in the global state.
 *
 * @function useUpdateCustomer
 * @returns {Object} An object containing the `updateCustomer` function.
 * @returns {Function} return.updateCustomer - A function to trigger the customer update request. 
 */
export const useUpdateCustomer = () => {
    // Dispatch function to update global state
    const globalStateDispatch = useGlobalStateDispatch();
    const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
    const { language } = useGlobalState();
    const { request } = usePromise(saleAPI.useUpdateCustomer, {
        onSuccess: ({ data = false }) => {
            setBasket(globalStateDispatch, data);
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_SUCCESS'), 'success');
        },
        // onFailure: () => { }
    });

    return {
        updateCustomer: request
    };
};



/**
 * Custom hook to check if a cart exists for a customer by their ID.
 *
 * This hook provides a function to initiate the request and 
 * returns the result indicating whether the cart exists.
 *
 * @returns {Object} - An object containing the following properties:
 * @returns {boolean|null} cartIsExist - Indicates if the cart exists (true or false), or null if not checked yet.
 * @returns {Function} isCartExist - Function to initiate the request to check if a cart exists.
 */
export const useCheckIfCartExistsByCustomerId = () => {

    const { licenceData: { documentCode, user: { licences } } } = useGlobalState();
   const {updateCustomer} = useUpdateCustomer();
    const { codeShop } = useSettingsApps();
    let copyEcommerceCustomerId = (licences || []).find(({ licenceCode }) => (licenceCode === codeShop))?.ecommerceCustomerId;

    const { getEcommerceBasket } = useGetEcommerceBasketFromCustomerIdOrDocumentCode();

    const hundlerGetEcommerceBasket = (isBasket, documentCode) => {

        if (isBasket) {
            getEcommerceBasket();
        } else if (documentCode && copyEcommerceCustomerId) {
            updateCustomer({documentCode,customerId:copyEcommerceCustomerId});
        }else if(documentCode){
            getEcommerceBasket(documentCode);

        }

    }

    const { result, request } = usePromise(saleAPI.useCheckIfCartExistsByCustomerId, {
        onSuccess: (res) => {
            hundlerGetEcommerceBasket(res.data, documentCode);
        },
        onFailure: () => {
            hundlerGetEcommerceBasket(false, documentCode);
        }
    })
    return {
        cartIsExist: result?.data,
        isCartExist: () => {
            if (copyEcommerceCustomerId)
                request(copyEcommerceCustomerId);
            else
                hundlerGetEcommerceBasket(false, documentCode);
        }
    }
};

/**
 * Custom hook to handle abandoning a sale document.
 * 
 * This hook provides a method (`abandonSaleDocument`) that triggers an API request to abandon
 * the current sale document. On success, it displays a success message and clears the basket
 * in the global state. On failure, it shows an error message.
 *
 * @returns {Object} An object with the `abandonSaleDocument` method to trigger the abandon sale document request.
 */
export const useAbandonSaleDocument = () => {
    const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
    const globalStateDispatch = useGlobalStateDispatch();
    const { language } = useGlobalState();

    const { request } = usePromise(saleAPI.useAbandonSaleDocument, {
        onSuccess: () => {
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_SUCCESS'), 'success');
            // TODO: Remove the document code from the browser cache if necessary.
            setBasket(globalStateDispatch, null);
        },
        onFailure: () => {
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_FAILED'), 'error');
        }
    });

    return {
        abandonSaleDocument: request
    };
};



/**
 * A custom hook for updating the quantity of a line item in a global state.
 *
 * @function
 * @returns {Object} An object containing the updateLineQuantity function.
 * @returns {Function} return.updateLineQuantity - A function to update the quantity of a line item.
 *
 * @example
 * const { updateLineQuantity } = useUpdateLineQuantity();
 * updateLineQuantity({ itemId: 1, quantity: 3 });
 */
export const useUpdateLineQuantity = (callback=()=>null) => {
    // Dispatch function to update global state
    const globalStateDispatch = useGlobalStateDispatch();
    const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
    const { language } = useGlobalState();
    const { request } = usePromise(saleAPI.updateLineQuantity, {
        onSuccess: ({ data = false }) => {
            setBasket(globalStateDispatch, data);
            callback();
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_SUCCESS'), 'success');
        },
        // onFailure: () => { }
    });

    return {
        updateLineQuantity: request
    };
};


/**
* Custom hook to handle adding an article by its ID to the current sale document.
* It utilizes global state, a sale API, and manages sale document creation if needed.
*
* @returns {Object} - An object containing the `addArticleById` function.
* 
* @function addArticleById
* @param {Object} body - The request body for adding an article, containing article-specific data.
* @example
* const { addArticleById } = useAddArticleById();
* addArticleById({ articleId: 12345 });
*/
var copyPropsAddArticleById = null;
export const useAddArticleById = (callback=()=>null) => {

    const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
    const { language } = useGlobalState();
    const { updateLineQuantity } = useUpdateLineQuantity(()=>callback())
    // Destructure necessary values from global state
    const { licenceData: { documentCode, saleDocument, user: { licences } } } = useGlobalState();

    // Hook to create a sale document
    const { createSaleDocument, resultCreateSaleDocument } = useCreateSaleDocument();

    // Retrieve the shop's code
    const { codeShop } = useSettingsApps();

    // Find the customer ID associated with the license code
    let customerId = (licences || []).find(({ licenceCode }) => (licenceCode === codeShop))?.ecommerceCustomerId;

    // Dispatch function to update global state
    const globalStateDispatch = useGlobalStateDispatch();

    // Handle adding the article using the sale API
    const { request } = usePromise(saleAPI.useAddArticleById, {
        /**
         * Success handler when the article is added.
         * Updates the basket and resets `copyPropsAddArticleById`.
         * @param {Object} response - The API response.
         * @param {Object} response.data - The updated sale document or basket data.
         */
        onSuccess: ({ data = null }) => {
            setBasket(globalStateDispatch, data);
            copyPropsAddArticleById = null;
            callback();
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'PRODUCT_ADDED_SUCCESSFULLY'), 'success');


        },

        /**
         * Failure handler when the request fails.
         * Resets `copyPropsAddArticleById`.
         */
        onFailure: () => {
            copyPropsAddArticleById = null;
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_FAILED'), 'error');
        }
    });

    // Effect that triggers when `documentCode` changes
    useEffect(() => {
        if (documentCode && resultCreateSaleDocument?.status === 200) 
            request({ ...copyPropsAddArticleById, key: documentCode });
    }, [resultCreateSaleDocument]); 
              

    return {
        /**
         * Adds an article by its ID to the sale document.
         * If the sale document doesn't exist, it will create one first.
         * 
         * @param {Object} body - The request body for the article.
         * @param {number} body.articleId - The ID of the article to add.
         * @param {number} body.quantity - The quantity of the article to add.
         */
        addArticleById: (body) => {
            if (!!documentCode && !!saleDocument) {
                let article = (body?.lines || [])?.filter(
                    item => (!item?.lineDeleted && !item?.lineCanceledReversal && !item?.lineCancellationReversal))?.find( item => item?.articleId === body?.articleId);
             
              
                if (!!article)
                    if (TransformToNumber(article?.quantityConditioning) + TransformToNumber(body?.quantity) > body?.availableStock && !body?.withNegativeStock)
                        loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'ERROR_AVAILABLE_STOCK'), 'error'); 
                    else    
                    updateLineQuantity({ ...body,
                    lineIndex: article?.lineIndex, 
                    quantity: (TransformToNumber(article?.quantityConditioning) + TransformToNumber(body?.quantity)) 
                });
                else
                    request(body);


            } else {
                copyPropsAddArticleById = body;
                createSaleDocument(customerId);
            }
        }
    };
};



/**
 * Custom hook to delete a line from the sale and update the global basket state.
 *
 * This hook leverages `usePromise` to handle API requests for deleting a sale line,
 * and updates the global state upon a successful request.
 *
 * @returns {Object} An object containing the deleteLine function, which is used to
 * initiate the delete request.
 *
 * @property {Function} deleteLine - The function to trigger the delete line request.
 * This function returns a promise and updates the basket in the global state if the
 * request is successful. 
 */
export const useDeleteLine = () => {
    // Dispatch function to update global state
    const globalStateDispatch = useGlobalStateDispatch();

    // API request for deleting a sale line, with a success callback
    const { request } = usePromise(saleAPI.useDeleteLine, {
        onSuccess: ({data=null}) => {
            setBasket(globalStateDispatch, data);
        }, 
    });

    return {
        deleteLine: request,
    };
};

/**
 * A custom hook for validating a sale document and updating the global state.
 *
 * @function
 * @returns {Object} An object containing the validateBasket function.
 * @returns {Function} return.validateBasket - A function to validate the sale document. 
 */
export const useValidateSaleDocument = () => {
    const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
    const { language } = useGlobalState();
    const { validateSaleDocumentParams } = useHandlerParamsApi();
    // Dispatch function to update global state
    const globalStateDispatch = useGlobalStateDispatch();
    const { request } = usePromise(saleAPI.useValidateSaleDocument, {
        onSuccess: () => {
            setBasket(globalStateDispatch, null);
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_SUCCESS'), 'success');
        },
        /*onFailure: () => {
           
           
        }*/
    });

    return {
        validateBasket: (body) => request(validateSaleDocumentParams(body))
    };
};