
import {api} from "../index";
export const useGetProductsCategories=()=>{
    return api.get('/dossiers/articleUniverses/ecom'/*,{
        headers: {
            Authorization: `Bearer ${token}`,
            "X-LICENCE-CODE": codeShop
        }

    }*/);
} ;